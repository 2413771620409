import React from "react";
import "../Styles/Main.css";
import { useNavigate } from "react-router-dom";
import group from "../img/Group 1.png";
import Map from "./Lefalet";

function Main() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/login");
  };
  const panelClick = () => {
    navigate("/panel");
  };

  return (
    <div className="main">
      <Map />

      <div className="main-buttons">

      
        <button onClick={handleClick}  id="button" >E Sınav Sonuç Açıklama</button>

       
  
      
        <button id="button" >  <a href="https://eitiraz.meb.gov.tr/">Sınav İtiraz Başvuru</a></button>
        
      </div>

      <div className="main-fotos">
        <img src={group} alt="group" />
      </div>

      <div className="main-footer">
        <p>Milli Eğitim Bakanlığı Bilgi İşlem Genel Müdürlüğü - 2021 Tüm Hakkı Saklıdır.</p>
      </div>
    </div>
  );
}

export default Main;
