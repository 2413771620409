import React, { useState } from 'react';
import '../Styles/Panelc.css';
import Panel from './Panel';

function PanelControl() {
  const correctPassword = '062632';
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password === correctPassword) {
      setIsAuthenticated(true);
    } else {
      alert('Yanlış Şifre');
    }
  };

  if (isAuthenticated) {
    return <Panel />;
  }

  return (
    <div className="panel-control-container">
      <form className="panel-control-form" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="password">Panel Şifresini Giriniz</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
          />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default PanelControl;
