import React, { useState } from "react";
import { getDatabase, ref, set, get, remove } from "firebase/database";
import '../Styles/Panel.css';
import UserList from "./Userlist";

function Panel() {
  const [tcNumber, setTcNumber] = useState("");
  const [userNotes, setUserNotes] = useState("");
  const [status, setStatus] = useState("");
  const [examName, setExamName] = useState("");
  const [examDate, setExamDate] = useState("");
  const [certificate, setCertificate] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [editable, setEditable] = useState(false); // Formun düzenlenebilir olup olmadığını takip eder

  const handleFetch = async () => {
    if (!tcNumber) {
      setError("TC numarası gereklidir");
      return;
    }

    const db = getDatabase();
    const userRef = ref(db, `/${tcNumber}`);

    try {
      const userSnapshot = await get(userRef);
      const userData = userSnapshot.val();

      if (userData) {
        setUserNotes(userData.not || "");
        setStatus(userData.durum || "");
        setExamName(userData.sınav_adi || "");
        setExamDate(userData.oturum_tarihi || "");
        setCertificate(userData.sertifika || "");
        setError(null);
        setSuccess("Veriler alındı.");
        setEditable(true); // Veriler alındığında form düzenlenebilir hale gelir
      } else {
        setUserNotes("");
        setStatus("");
        setExamName("");
        setExamDate("");
        setCertificate("");
        setError("Kullanıcı bulunamadı. Verileri kendiniz girebilirsiniz.");
        setSuccess(null);
        setEditable(true); // Kullanıcı bulunamadığında form düzenlenebilir hale gelir
      }
    } catch (error) {
      console.error("Firebase'den veri alırken bir hata oluştu:", error);
      setError("Veri alınırken bir hata oluştu.");
      setSuccess(null);
      setEditable(false); // Hata durumunda form düzenlenemez
    }
  };

  const handleSave = async () => {
    if (!tcNumber) {
      setError("TC numarası gereklidir");
      return;
    }

    const db = getDatabase();
    const userRef = ref(db, `/${tcNumber}`);

    try {
      await set(userRef, {
        not: userNotes,
        durum: status,
        sınav_adi: examName,
        oturum_tarihi: examDate,
        sertifika: certificate
      });
      setError(null);
      setSuccess("Veriler kaydedildi.");
      setEditable(false); // Veriler kaydedildikten sonra form düzenlenemez hale gelir
    } catch (error) {
      console.error("Firebase'e veri kaydedilirken bir hata oluştu:", error);
      setError("Veri kaydedilirken bir hata oluştu.");
      setSuccess(null);
      setEditable(true); // Hata durumunda form düzenlenebilir kalır
    }
  };

  const handleDelete = async () => {
    if (!tcNumber) {
      setError("TC numarası gereklidir");
      return;
    }

    const db = getDatabase();
    const userRef = ref(db, `/${tcNumber}`);

    try {
      await remove(userRef);
      setUserNotes("");
      setStatus("");
      setExamName("");
      setExamDate("");
      setCertificate("");
      setError(null);
      setSuccess("Kullanıcı verileri silindi.");
      setEditable(false); // Veriler silindikten sonra form düzenlenemez hale gelir
    } catch (error) {
      console.error("Firebase'den veri silinirken bir hata oluştu:", error);
      setError("Veri silinirken bir hata oluştu.");
      setSuccess(null);
    }
  };

  return (
    <div className="panel">
      <h2>Veri Güncelleme Paneli</h2>
      {error && <div className="error">{error}</div>}
      {success && <div className="success">{success}</div>}
      <div className="form-group">
        <label>TC Numarası:</label>
        <input
          type="text"
          value={tcNumber}
          onChange={(e) => setTcNumber(e.target.value)}
        />
      </div>
      <button id="veri" onClick={handleFetch}>Verileri Getir</button>
      {editable && (
        <>
          <div className="form-group">
            <label>Sınav Adı:</label>
            <input
              type="text"
              value={examName}
              onChange={(e) => setExamName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Oturum Tarihi:</label>
            <input
              type="text"
              value={examDate}
              onChange={(e) => setExamDate(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Sertifika:</label>
            <input
              type="text"
              value={certificate}
              onChange={(e) => setCertificate(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Kullanıcı Notu:</label>
            <input
              type="text"
              value={userNotes}
              onChange={(e) => setUserNotes(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Durum:</label>
            <input
              type="text"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            />
          </div>
          <button onClick={handleSave}>Kaydet</button>
          <button id="sil" onClick={handleDelete}>Sil</button>
        </>
      )}
      <UserList />

      
    </div>
  );
}

export default Panel;
