import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getDatabase, ref, get } from "firebase/database";
import "../Styles/Result.css";

function Result() {
  const [userNotes, setUserNotes] = useState(null);
  const [status, setStatus] = useState(null);
  const [examName, setExamName] = useState(null);
  const [examDate, setExamDate] = useState(null);
  const [certificate, setCertificate] = useState(null);
  const [error, setError] = useState(null);
  const location = useLocation();
  const tcNumber = location.state ? location.state.tcNumber : null;

  useEffect(() => {
    const fetchData = async () => {
      if (!tcNumber) return;

      const db = getDatabase();
      const userNotesRef = ref(db, `/${tcNumber}/not`);
      const userStatusRef = ref(db, `/${tcNumber}/durum`);
      const examNameRef = ref(db, `/${tcNumber}/sınav_adi`);
      const examDateRef = ref(db, `/${tcNumber}/oturum_tarihi`);
      const certificateRef = ref(db, `/${tcNumber}/sertifika`);

      try {
        const [
          userNotesSnapshot,
          userStatusSnapshot,
          examNameSnapshot,
          examDateSnapshot,
          certificateSnapshot,
        ] = await Promise.all([
          get(userNotesRef),
          get(userStatusRef),
          get(examNameRef),
          get(examDateRef),
          get(certificateRef),
        ]);

        const userNotesData = userNotesSnapshot.val();
        const userStatusData = userStatusSnapshot.val();
        const examNameData = examNameSnapshot.val();
        const examDateData = examDateSnapshot.val();
        const certificateData = certificateSnapshot.val();

        const notes = userNotesData ? parseInt(userNotesData, 10) : null;

        console.log("Not:", notes);
        console.log("Durum:", userStatusData);
        console.log("Sınav Adı:", examNameData);
        console.log("Oturum Tarihi:", examDateData);
        console.log("Sertifika:", certificateData);

        if (notes !== null) {
          setUserNotes(notes);
          setStatus(userStatusData);
          setExamName(examNameData);
          setExamDate(examDateData);
          setCertificate(certificateData);
        } else {
          setError("Kullanıcı bulunamadı veya not verisi yok.");
        }
      } catch (error) {
        console.error("Firebase'den veri alırken bir hata oluştu:", error);
        setError("Veri alınırken bir hata oluştu.");
      }
    };

    fetchData();
  }, [tcNumber]);

  return (
    <div className="result">
      <div className="result-see">
        <div className="result-intro">
          <p>T.C Milli Eğitim Bakanlığı</p>
          <p>Ölçme, Değerlendirme ve Sınav Hizmetleri Müdürlüğü</p>
          <p>Elektronik Sınav Sonuç Açıklama Ekranı</p>
        </div>
       
        <div className="result-content">
          <div className="title-item-pair">
            <div className="title">TC NO</div>
            <div className="item">{tcNumber}</div>
          </div>
          <div className="title-item-pair">
            <div className="title">SINAV ADI</div>
            <div className="item">{examName}</div>
          </div>
          <div className="title-item-pair">
            <div className="title">OTURUM TARİHİ</div>
            <div className="item">{examDate}</div>
          </div>
          <div className="title-item-pair">
            <div className="title">SERTİFİKA TÜRÜ</div>
            <div className="item">{certificate}</div>
          </div>
          <div className="title-item-pair">
            <div className="title">SINAV PUANI</div>
            <div className="item">{userNotes}</div>
          </div>
          <div className="title-item-pair">
            <div className="title">DURUMU</div>
            <div className="item">{status}</div>
          </div>
          {error && <div className="error">{error}</div>}
        </div>
      </div>
    </div>
  );
}

export default Result;
