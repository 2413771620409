import React, { useEffect } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css"; // Leaflet CSS dosyasını ekleyin
import "../Styles/Lefalet.css"
import { useNavigate } from "react-router-dom";

const eSınavMerkezleri = [
  { name: "Adana E-Sınav Merkezi", location: [37.0004, 35.3213] },
  { name: "Adıyaman E-Sınav Merkezi", location: [37.7648, 38.2768] },
  { name: "Afyonkarahisar E-Sınav Merkezi", location: [38.7598, 30.5384] },
  { name: "Ağrı E-Sınav Merkezi", location: [39.7191, 43.0567] },
  { name: "Amasya E-Sınav Merkezi", location: [40.6495, 35.8283] },
  { name: "Ankara E-Sınav Merkezi", location: [39.9334, 32.8597] },
  { name: "Antalya E-Sınav Merkezi", location: [36.8969, 30.7133] },
  { name: "Artvin E-Sınav Merkezi", location: [41.1399, 41.8162] },
  { name: "Aydın E-Sınav Merkezi", location: [37.856, 27.8416] },
  { name: "Balıkesir E-Sınav Merkezi", location: [39.6484, 27.8826] },
  { name: "Bilecik E-Sınav Merkezi", location: [40.0567, 30.0665] },
  { name: "Bingöl E-Sınav Merkezi", location: [39.0663, 40.724] },
  { name: "Bitlis E-Sınav Merkezi", location: [38.4004, 42.109] },
  { name: "Bolu E-Sınav Merkezi", location: [40.6009, 31.5788] },
  { name: "Burdur E-Sınav Merkezi", location: [37.5345, 30.2866] },
  { name: "Bursa E-Sınav Merkezi", location: [40.1824, 29.067] },
  { name: "Çanakkale E-Sınav Merkezi", location: [40.1553, 26.4142] },
  { name: "Çankırı E-Sınav Merkezi", location: [40.6025, 33.6134] },
  { name: "Çorum E-Sınav Merkezi", location: [40.5489, 34.953] },
  { name: "Denizli E-Sınav Merkezi", location: [37.7765, 29.0864] },
  { name: "Diyarbakır E-Sınav Merkezi", location: [37.9144, 40.2306] },
  { name: "Edirne E-Sınav Merkezi", location: [41.6753, 26.5587] },
  { name: "Elazığ E-Sınav Merkezi", location: [38.681, 39.2264] },
  { name: "Erzincan E-Sınav Merkezi", location: [39.7392, 39.4918] },
  { name: "Erzurum E-Sınav Merkezi", location: [39.9208, 41.2746] },
  { name: "Eskişehir E-Sınav Merkezi", location: [39.7669, 30.5256] },
  { name: "Gaziantep E-Sınav Merkezi", location: [37.0662, 37.3833] },
  { name: "Giresun E-Sınav Merkezi", location: [40.9128, 38.3895] },
  { name: "Gümüşhane E-Sınav Merkezi", location: [40.4606, 39.4714] },
  { name: "Hakkari E-Sınav Merkezi", location: [37.5744, 43.7407] },
  { name: "Hatay E-Sınav Merkezi", location: [36.2048, 36.1619] },
  { name: "Isparta E-Sınav Merkezi", location: [37.7742, 30.5525] },
  { name: "Mersin E-Sınav Merkezi", location: [36.8049, 34.631] },
  { name: "İstanbul E-Sınav Merkezi", location: [41.0082, 28.9784] },
  { name: "İzmir E-Sınav Merkezi", location: [38.4192, 27.1287] },
  { name: "Kars E-Sınav Merkezi", location: [40.6084, 43.097] },
  { name: "Kastamonu E-Sınav Merkezi", location: [41.3805, 33.7826] },
  { name: "Kayseri E-Sınav Merkezi", location: [38.7369, 35.467] },
  { name: "Kırklareli E-Sınav Merkezi", location: [41.737, 27.2156] },
  { name: "Kırşehir E-Sınav Merkezi", location: [39.1425, 34.1709] },
  { name: "Kocaeli E-Sınav Merkezi", location: [40.8533, 29.8815] },
  { name: "Konya E-Sınav Merkezi", location: [37.8716, 32.4846] },
  { name: "Kütahya E-Sınav Merkezi", location: [39.4192, 29.983] },
  { name: "Malatya E-Sınav Merkezi", location: [38.3552, 38.3095] },
  { name: "Manisa E-Sınav Merkezi", location: [38.6191, 27.4281] },
  { name: "Kahramanmaraş E-Sınav Merkezi", location: [37.57, 36.937] },
  { name: "Mardin E-Sınav Merkezi", location: [37.324, 40.724] },
  { name: "Amasya E-Sınav Merkezi", location: [40.6495, 35.8283] },
  { name: "Ankara E-Sınav Merkezi", location: [39.9334, 32.8597] },
  { name: "Antalya E-Sınav Merkezi", location: [36.8969, 30.7133] },
  { name: "Artvin E-Sınav Merkezi", location: [41.1399, 41.8162] },
  { name: "Aydın E-Sınav Merkezi", location: [37.856, 27.8416] },
  { name: "Balıkesir E-Sınav Merkezi", location: [39.6484, 27.8826] },
  { name: "Bilecik E-Sınav Merkezi", location: [40.0567, 30.0665] },
  { name: "Bingöl E-Sınav Merkezi", location: [39.0663, 40.724] },
  { name: "Bitlis E-Sınav Merkezi", location: [38.4004, 42.109] },
  { name: "Bolu E-Sınav Merkezi", location: [40.6009, 31.5788] },
  { name: "Burdur E-Sınav Merkezi", location: [37.5345, 30.2866] },
  { name: "Bursa E-Sınav Merkezi", location: [40.1824, 29.067] },
  { name: "Çanakkale E-Sınav Merkezi", location: [40.1553, 26.4142] },
  { name: "Çankırı E-Sınav Merkezi", location: [40.6025, 33.6134] },
  { name: "Çorum E-Sınav Merkezi", location: [40.5489, 34.953] },
  { name: "Denizli E-Sınav Merkezi", location: [37.7765, 29.0864] },
  { name: "Diyarbakır E-Sınav Merkezi", location: [37.9144, 40.2306] },
  { name: "Edirne E-Sınav Merkezi", location: [41.6753, 26.5587] },
  { name: "Elazığ E-Sınav Merkezi", location: [38.681, 39.2264] },
  { name: "Erzincan E-Sınav Merkezi", location: [39.7392, 39.4918] },
  { name: "Erzurum E-Sınav Merkezi", location: [39.9208, 41.2746] },
  { name: "Eskişehir E-Sınav Merkezi", location: [39.7669, 30.5256] },
  { name: "Gaziantep E-Sınav Merkezi", location: [37.0662, 37.3833] },
  { name: "Giresun E-Sınav Merkezi", location: [40.9128, 38.3895] },
  { name: "Gümüşhane E-Sınav Merkezi", location: [40.4606, 39.4714] },
  { name: "Hakkari E-Sınav Merkezi", location: [37.5744, 43.7407] },
  { name: "Hatay E-Sınav Merkezi", location: [36.2048, 36.1619] },
  { name: "Isparta E-Sınav Merkezi", location: [37.7742, 30.5525] },
  { name: "Mersin E-Sınav Merkezi", location: [36.8049, 34.631] },
  { name: "İstanbul E-Sınav Merkezi", location: [41.0082, 29.9784] },
  { name: "İstanbul E-Sınav Merkezi", location: [41.0082, 29.9784] },
  { name: "İstanbul E-Sınav Merkezi", location: [41.0082, 29.9784] },
  { name: "İstanbul E-Sınav Merkezi", location: [40.2082, 29.9784] },
  { name: "İzmir E-Sınav Merkezi", location: [38.4192, 27.1287] },
  { name: "Kars E-Sınav Merkezi", location: [40.6084, 43.097] },
  { name: "Kastamonu E-Sınav Merkezi", location: [41.3805, 33.7826] },
  { name: "Kayseri E-Sınav Merkezi", location: [38.7369, 35.467] },
  { name: "Kırklareli E-Sınav Merkezi", location: [41.737, 27.2156] },
  { name: "Kırşehir E-Sınav Merkezi", location: [39.1425, 34.1709] },
  { name: "Kocaeli E-Sınav Merkezi", location: [40.8533, 29.8815] },
  { name: "Konya E-Sınav Merkezi", location: [37.8716, 32.4846] },
  { name: "İzmir E-Sınav Merkezi", location: [38.4192, 27.1287] },
  
{ name: "Kars E-Sınav Merkezi", location: [40.6084, 43.097] },
{ name: "Kastamonu E-Sınav Merkezi", location: [41.3805, 33.7826] },
{ name: "Kayseri E-Sınav Merkezi", location: [38.7369, 35.467] },
{ name: "Kırklareli E-Sınav Merkezi", location: [41.737, 27.2156] },
{ name: "Kırşehir E-Sınav Merkezi", location: [39.1425, 34.1709] },
{ name: "Kocaeli E-Sınav Merkezi", location: [40.8533, 29.8815] },
{ name: "Muğla E-Sınav Merkezi", location: [37.1647, 28.3672] },
{ name: "Muş E-Sınav Merkezi", location: [38.7489, 41.5065] },
{ name: "Nevşehir E-Sınav Merkezi", location: [38.6246, 34.7127] },
{ name: "Niğde E-Sınav Merkezi", location: [37.9655, 34.6766] },
{ name: "Ordu E-Sınav Merkezi", location: [40.9826, 37.8797] },
{ name: "Rize E-Sınav Merkezi", location: [41.0201, 40.5234] },
{ name: "Sakarya E-Sınav Merkezi", location: [40.7799, 30.4035] },
{ name: "Samsun E-Sınav Merkezi", location: [41.2867, 36.33] },
{ name: "Siirt E-Sınav Merkezi", location: [37.9339, 41.9404] },
{ name: "Konya E-Sınav Merkezi", location: [37.8716, 32.4846] },
{ name: "Kütahya E-Sınav Merkezi", location: [39.4192, 29.983] },
{ name: "Malatya E-Sınav Merkezi", location: [38.3552, 38.3095] },
{ name: "Manisa E-Sınav Merkezi", location: [38.6191, 27.4281] },
{ name: "Kahramanmaraş E-Sınav Merkezi", location: [37.57, 36.937] },
{ name: "Mardin E-Sınav Merkezi", location: [37.324, 40.724] },

  { name: "Kütahya E-Sınav Merkezi", location: [39.4192, 29.983] },
  { name: "Malatya E-Sınav Merkezi", location: [38.3552, 38.3095] },
  { name: "Manisa E-Sınav Merkezi", location: [38.6191, 27.4281] },
  { name: "Kahramanmaraş E-Sınav Merkezi", location: [37.57, 36.937] },
  { name: "Mardin E-Sınav Merkezi", location: [37.324, 40.724] },
  { name: "Sinop E-Sınav Merkezi", location: [42.0231, 35.1539] },
{ name: "Sivas E-Sınav Merkezi", location: [39.7500, 37.0158] },
{ name: "Tekirdağ E-Sınav Merkezi", location: [40.9780, 27.5035] },
{ name: "Tokat E-Sınav Merkezi", location: [40.3118, 36.5509] },
{ name: "Trabzon E-Sınav Merkezi", location: [41.0050, 39.7269] },
{ name: "Tunceli E-Sınav Merkezi", location: [39.1076, 39.5475] },
{ name: "Şanlıurfa E-Sınav Merkezi", location: [37.1391, 38.7969] },
{ name: "Uşak E-Sınav Merkezi", location: [38.6823, 29.4082] },
{ name: "Van E-Sınav Merkezi", location: [38.4956, 43.3837] },
{ name: "Yozgat E-Sınav Merkezi", location: [39.8219, 34.8048] },
{ name: "Zonguldak E-Sınav Merkezi", location: [41.4564, 31.7987] },
{ name: "Aksaray E-Sınav Merkezi", location: [38.3592, 34.0268] },
{ name: "Bayburt E-Sınav Merkezi", location: [40.2558, 40.2247] },
{ name: "Karaman E-Sınav Merkezi", location: [37.1759, 33.2288] },
{ name: "Kırıkkale E-Sınav Merkezi", location: [39.8679, 33.5083] },
{ name: "Batman E-Sınav Merkezi", location: [37.892, 41.1351] },
{ name: "Şırnak E-Sınav Merkezi", location: [37.5175, 42.4605] },
{ name: "Bartın E-Sınav Merkezi", location: [41.634, 32.3378] },
{ name: "Ardahan E-Sınav Merkezi", location: [41.1092, 42.7026] },
{ name: "Iğdır E-Sınav Merkezi", location: [39.8815, 44.0469] },
{ name: "Yalova E-Sınav Merkezi", location: [40.655, 29.2757] },
{ name: "Karabük E-Sınav Merkezi", location: [41.2048, 32.6292] },
{ name: "Kilis E-Sınav Merkezi", location: [36.7161, 37.115] },
{ name: "Osmaniye E-Sınav Merkezi", location: [37.2488, 36.2807] },
{ name: "Düzce E-Sınav Merkezi", location: [40.837, 31.1648] },
{ name: "Ankara E-Sınav Merkezi", location: [39.9334, 32.8597] },

];


function Map() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/panel");
  };
  useEffect(() => {
    const map = L.map("mapid").setView([39.9334, 32.8597], 6);

    L.tileLayer(
      "https://c.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png",
      {
        attribution:
          'Map data &copy; <a href="https://www.meb.gov.tr">MEB</a>, Imagery © <a href="https://leafletjs.com">Leaflet</a>',
        maxZoom: 19,
      }
    ).addTo(map);

    const eSınavIcon = L.icon({
      iconUrl: "https://esinav.meb.gov.tr/leaflet/images/marker-icon-2x.png",
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
    });

    eSınavMerkezleri.forEach((merkez) => {
      L.marker(merkez.location, { icon: eSınavIcon })
        .addTo(map)
        .bindPopup(`<b>${merkez.name}</b>`)
        .openPopup();
    });

    return () => {
      map.remove();
    };
  }, []);

  return (
    <div className="map-container">
       <button  className="pnlbtn" onClick={handleClick}></button>
      <p className="map-header">
     
        E Sınav Merkezlerimiz
      </p>
      <div
        id="mapid"
      ></div>
     
    
    </div>
  );
}

export default Map;
