import React, { useState, useEffect } from "react";
import { getDatabase, ref, get } from "firebase/database";
import '../Styles/UserList.css';

function UserList() {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      const db = getDatabase();
      const usersRef = ref(db);

      try {
        const snapshot = await get(usersRef);
        const data = snapshot.val();
        const usersArray = data ? Object.keys(data).map(tcNumber => ({ tcNumber, ...data[tcNumber] })) : [];
        setUsers(usersArray);
        setError(null);
      } catch (error) {
        console.error("Firebase'den veri alırken bir hata oluştu:", error);
        setError("Veri alınırken bir hata oluştu.");
      }
    };

    fetchUsers();
  }, []);

  return (
    <div className="user-list">
      <h2>Tüm Kullanıcılar</h2>
      {error && <div className="error">{error}</div>}
      <table>
        <thead>
          <tr>
            <th>TC Numarası</th>
            
          
            <th>Sınav Adı</th>
            <th>Oturum Tarihi</th>
            <th>Sertifika</th>
            <th>Kullanıcı Notu</th>
            <th>Durum</th>
            
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.tcNumber}>
              <td>{user.tcNumber}</td>
              <td>{user.not || "N/A"}</td>
              <td>{user.durum || "N/A"}</td>
              <td>{user.sınav_adi || "N/A"}</td>
              <td>{user.oturum_tarihi || "N/A"}</td>
              <td>{user.sertifika || "N/A"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default UserList;
