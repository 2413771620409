import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import Main from './Components/Main';
import Result from './Components/Result';
import Login from './Components/Login';
import Panel from './Components/Panel';
import PanelControl from './Components/PanelControl';




function App() {
  return (
  
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/panel" element={<PanelControl />} />
    

       
       
        <Route path="/login" element={<Login />} />
        <Route path="/result" element={<Result />} />
      </Routes>
    </Router>
  );
}

export default App;
