import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, get } from "firebase/database";
import "../Styles/Login.css";

// Firebase konfigürasyonu
const firebaseConfig = {
    apiKey: "AIzaSyAWqgwej1RDum1qnAmCs0c22vdXOIIEz0s",
    authDomain: "driver-a1f30.firebaseapp.com",
    databaseURL: "https://driver-a1f30-default-rtdb.firebaseio.com",
    projectId: "driver-a1f30",
    storageBucket: "driver-a1f30.appspot.com",
    messagingSenderId: "583657849686",
    appId: "1:583657849686:web:79ba023889817f60a0ccf7"
};
// Firebase uygulamasını başlat
const app = initializeApp(firebaseConfig);

function Login() {
  const [tcNumber, setTcNumber] = useState("");
  const [securityCode, setSecurityCode] = useState("");
  const [randomSecurityCode, setRandomSecurityCode] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // useNavigate kullanılması

  useEffect(() => {
    generateRandomSecurityCode();
  }, []);

  const generateRandomSecurityCode = () => {
    const randomCode = Math.floor(1000 + Math.random() * 9000);
    setRandomSecurityCode(randomCode.toString());
  };

  const handleLogin = async () => {
    if (securityCode === randomSecurityCode) {
      const db = getDatabase();
      const userRef = ref(db, `/${tcNumber}/not`);
      try {
        const userSnapshot = await get(userRef);
        
        if (userSnapshot.exists()) {
          // Kullanıcı bulundu, notları işle
          const userNotes = userSnapshot.val();
        
          // userNotes içindeki notları işleyebilirsiniz.
          console.log("tcNumber:", tcNumber);

          navigate('/result', { state: { tcNumber: tcNumber } });

        } else {
          setError("Kullanıcı bulunamadı.");
        }
      } catch (error) {
        console.error("Firebase'den veri alırken bir hata oluştu:", error);
        setError("Veri alınırken bir hata oluştu.");
      }
    } else {
      setError("Güvenlik kodu yanlış.");
    }
  };

  return (
    <div className="login">
      <div className="loginbox">
      <div className="login-title">
          <p>T.C Milli Eğitim Bakanlığı</p>
          <p>Ölçme, Değerlendirme ve Sınav Hizmetleri Müdürlüğü</p>
          <p>Elektronik Sınav Sonuç Açıklama Ekranı</p>
        </div>
        
      <div className="log-box-input">
     
      
          <div className="input-tc">
            <label for="tc">Aday TC :</label>
            <input 
              type="text" 
              id="tc" 
              value={tcNumber} 
              onChange={(e) => setTcNumber(e.target.value)}
            />
          </div>


          <div className="sec-code">
            <h5>{randomSecurityCode}</h5>
          </div>


          <div className="input-code">
            <label for="guvenlik">Güvenlik Kodu :</label>
            <input 
              type="text" 
              id="guvenlik" 
              value={securityCode} 
              onChange={(e) => setSecurityCode(e.target.value)}
            />
          </div>
          <div className="login-btn">
            <button className="query-btn" onClick={handleLogin}>Sorgula</button>
          </div>
          {error && <div className="error">{error}</div>}
        </div>



      </div>
    </div>
  );
}

export default Login;
